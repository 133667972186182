<template>
    <Layout>
        <h4>Home Improvement Request</h4>
        <br />
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestHomeImprovement"
            ref="formContainer"
        >
            <div class="row mt-2">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="homeImp.unit_id"
                            :options="mappedUserUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.homeImp.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.homeImp.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.homeImp.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"> Date* </label>
                        <b-form-datepicker
                            :min="min"
                            v-model="homeImp.date"
                            :class="{
                                'is-invalid':
                                    submitted && $v.homeImp.date.$error,
                            }"
                        ></b-form-datepicker>

                        <div
                            v-if="submitted && $v.homeImp.date.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.homeImp.date.required"
                                >Date is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contractor Name*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-model="homeImp.contractor_name"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.homeImp.contractor_name.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted && $v.homeImp.contractor_name.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.homeImp.contractor_name.required"
                                >Contractor name required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contact Person*</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-model="homeImp.contact_person_name"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.homeImp.contact_person_name.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.homeImp.contact_person_name.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="!$v.homeImp.contact_person_name.required"
                                >Contact person name required</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"
                            >Contact Person Mobile Number*</label
                        >
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-model="homeImp.contact_person_mobile_no"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.homeImp.contact_person_mobile_no.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.homeImp.contact_person_mobile_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.homeImp.contact_person_mobile_no
                                        .required
                                "
                                >Mobile number required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"
                            >Contact Person Office Number</label
                        >
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-model="homeImp.contact_person_office_no"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Contact Person Fax</label>
                        <input
                            type="text"
                            class="form-control mb-3"
                            id="username"
                            v-model="homeImp.contact_person_fax_no"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.homeImp.contact_person_fax_no.$error,
                            }"
                        />
                        <div
                            v-if="
                                submitted &&
                                $v.homeImp.contact_person_fax_no.$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="!$v.homeImp.contact_person_fax_no.numeric"
                                >Only numeric values allowed</span
                            >
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">Type Of Alteration*</label>

                    <b-form-checkbox-group
                        :options="homeImprovementOptions"
                        class="mb-3"
                        v-model="homeImp.type"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                        :disabled="!isSwimming"
                        @change="showOther($event)"
                        :class="{
                            'is-invalid': submitted && $v.homeImp.type.$error,
                        }"
                    ></b-form-checkbox-group>
                    <div
                        v-if="submitted && $v.homeImp.type.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.homeImp.type.required"
                            >Please chooose an alteration type</span
                        >
                    </div>
                </div>
            </div>
            <div class="row mt-2" v-if="isOther">
                <div class="col-lg-12">
                    <label for="ownerfname"> Other</label>
                    <b-form-textarea
                        id="textarea-small"
                        v-model="homeImp.type_other"
                        size="sm"
                    ></b-form-textarea>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-lg-12">
                    <label for="ownerfname">
                        Description of your alteration*</label
                    >
                    <div class="form-group">
                        <b-form-textarea
                            id="textarea-small"
                            size="sm"
                            v-model="homeImp.details"
                            :class="{
                                'is-invalid':
                                    submitted && $v.homeImp.details.$error,
                            }"
                        ></b-form-textarea>
                        <div
                            v-if="submitted && $v.homeImp.details.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.homeImp.details.required"
                                >Description is required
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-6">
                    <label for="ownerfname">Trade License Copy*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleTrade"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.homeImp.trade_licence.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.homeImp.trade_licence.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.homeImp.trade_licence.required"
                            >Trade license is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">Letter of Undertaking*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleUnderTaking"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.homeImp.letter_of_undertaking.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted && $v.homeImp.letter_of_undertaking.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.homeImp.letter_of_undertaking.required"
                            >Letter of undertaking is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-6">
                    <label for="ownerfname">Letter of Agreement*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleAgreement"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.homeImp.letter_of_agreement.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted && $v.homeImp.letter_of_agreement.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.homeImp.letter_of_agreement.required"
                            >Agreement letter is required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname">Drawings*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleDrawing"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.homeImp.drawings.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.homeImp.drawings.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.homeImp.drawings.required"
                            >Drawing is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-6">
                    <label for="ownerfname">Scope of Work*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleScope"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.homeImp.scope_of_work.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.homeImp.scope_of_work.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.homeImp.scope_of_work.required"
                            >Scope of work required</span
                        >
                    </div>
                </div>
                <div class="col-lg-6">
                    <label for="ownerfname"> Company Request Letter*</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleRequest"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.homeImp.company_request_letter.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted &&
                            $v.homeImp.company_request_letter.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.homeImp.company_request_letter.required"
                            >Release letter is required</span
                        >
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Bank Noc(If Any)</label>
                        <b-form-file
                            placeholder="Choose File"
                            drop-placeholder="Drop file here..."
                            @change="handleNoc"
                            class="mb-3"
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                        ></b-form-file>
                    </div>
                </div>
            </div>

            <h5>Terms & Conditions</h5>

            <ol>
                <li>
                    The vendor hereby consents to the alterations /
                    modifications in accordance with the details of the
                    alterations, additions & home improvements form annexed
                    hereto. This consent is limited to these alteration,
                    additions & home improvements only and is not to be taken as
                    authorizing any other changes. However necessary approval to
                    be obtained by the customer from local authorities.
                </li>
                <li>
                    This consent grants permission to your engineers to enter
                    for any inspection during the time of alteration, additions
                    and home improvements.
                </li>
                <li>
                    This consent is granted subject to I am not disturbing the
                    neighborhood and being responsible for any and all damage to
                    roadways, paths, fixtures, services, utilities and any other
                    property arising from the works. Additionally, any
                    de-watering process carried out during alterations/
                    additions must be extracted to the storm water drainage
                    system and NOT to the sewerage system.
                </li>
                <li>
                    In order to maintain the cleanliness of your neighborhood, I
                    must provide a skip or trash bin sufficient to hold all
                    construction/ plantation debris, in front of the unit during
                    the course of work. Failure to provide this will result in
                    the Security Deposit being forfeited without notice. On
                    completion of work I must, at my own cost, arrange for all
                    waster debris to be removed from the premises and the
                    surrounding area.
                </li>
                <li>
                    <span style="background-color: yellow; font-size: 1.2em"
                        ><strong
                            >“The area targeted by alteration in the said unit
                            will be out of DLP Defects liability
                            period.”</strong
                        ></span
                    >
                </li>
                <li>
                    Owner’s need to obtain Dubai Municipality NOC / Permit for
                    construction of swimming pool. Dubai Municipality permit /
                    NOC must be submitted along with the refund request in order
                    to avail refund of security deposit for swimming pool.
                </li>
            </ol>
            <p>
                For any more information please don’t hesitate to contact us on
                Tel no: +971 4 327 1737 OR Email: customerservice@falconcity.com
            </p>
            <br />
            <b-form-checkbox
                id="checkbox-1"
                @change="agree($event)"
                name="checkbox-1"
                value="true"
                unchecked-value="not_accepted"
            >
                I understand and agree to the Terms & Conditions mentioned above
            </b-form-checkbox>
            <div class="mt-3 mb-3">
                <button
                    type="submit"
                    :disabled="!agreed"
                    class="btn btn-primary"
                >
                    Submit
                </button>
            </div>
        </form>
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
// eslint-disable-next-line no-unused-vars
import { BASE_URL, HOME_IMPROVEMENT_TYPES } from "../../common";
import axios from "axios";

import { required, numeric } from "vuelidate/lib/validators";

// import axios from "axios";
export default {
    mixins: [alertMixin],
    components: {
        Layout,
    },
    validations: {
        homeImp: {
            company_request_letter: { required },
            contact_person_mobile_no: { required, numeric },
            contact_person_name: { required },
            contractor_name: { required },
            date: { required },
            details: { required },
            drawings: { required },
            contact_person_fax_no: { numeric },
            letter_of_agreement: { required },
            letter_of_undertaking: { required },
            scope_of_work: { required },
            trade_licence: { required },
            type: { required },
            unit_id: { required },
        },
    },
    data() {
        const now = new Date();
        const today = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate()
        );
        const minDate = new Date(today);
        return {
            submitted: false,
            isOther: false,
            agreed: false,
            isSwimming: true,
            min: minDate,
            payment: "",
            selected: "",
            items: [],
            homeImp: {
                bank_noc: "",
                bank_noc_filename: "",
                company_request_letter: "",
                company_request_letter_filename: "",
                contact_person_fax_no: "",
                contact_person_mobile_no: "",
                contact_person_name: "",
                contact_person_office_no: "",
                contractor_name: "",
                date: "",
                details: "",
                drawings: "",
                drawings_filename: "",
                letter_of_agreement: "",
                letter_of_agreement_filename: "",
                letter_of_undertaking: "",
                letter_of_undertaking_filename: "",
                scope_of_work: "",
                scope_of_work_filename: "",
                trade_licence: "",
                trade_licence_filename: "",
                type: [],
                type_other: "",
                unit_id: "",
            },
        };
    },
    mounted() {
        this.$store.dispatch("apidata/fetchCountries");
    },
    methods: {
        requestHomeImprovement() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.homeImp);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}homeimprovements?token=${localStorage.cs_user_token}`,
                            this.homeImp
                        )
                        .then((res) => {
                            this.resetFields();
                            this.agreed = false;
                            console.log(res.data.response);
                            this.successMsg(
                                res.data.response.ref_no,
                                "Success! Your request has been received by the Customer Service Team."
                            );
                            loader.hide();
                            this.$router.push("/");
                        })

                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        showOther(e) {
            let result = this.homeImp.type.find((el) => el === "Other");
            let swimming = this.homeImp.type.find(
                (el) => el === "Swimming Pool"
            );
            if (swimming) {
                this.isSwimming = false;
                this.homeImp.type = [];
                this.homeImp.type.push("Swimming Pool");
            }
            if (result == undefined) {
                this.isOther = false;
            } else {
                this.isOther = true;
            }
        },
        // eslint-disable-next-line no-unused-vars
        agree(e) {
            console.log("testing ", this.agreed);
            this.agreed = !this.agreed;
        },

        handleTrade(e) {
            let reader = new FileReader();
            this.homeImp.trade_licence_filename = e.target.files[0].name;

            reader.onload = (e) => {
                this.homeImp.trade_licence = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleUnderTaking(e) {
            let reader = new FileReader();
            this.homeImp.letter_of_undertaking_filename =
                e.target.files[0].name;

            reader.onload = (e) => {
                this.homeImp.letter_of_undertaking =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleAgreement(e) {
            let reader = new FileReader();
            this.homeImp.letter_of_agreement_filename = e.target.files[0].name;

            reader.onload = (e) => {
                this.homeImp.letter_of_agreement =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleDrawing(e) {
            let reader = new FileReader();
            this.homeImp.drawings_filename = e.target.files[0].name;

            reader.onload = (e) => {
                this.homeImp.drawings = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleScope(e) {
            let reader = new FileReader();
            this.homeImp.scope_of_work_filename = e.target.files[0].name;

            reader.onload = (e) => {
                this.homeImp.scope_of_work = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleRequest(e) {
            let reader = new FileReader();
            this.homeImp.company_request_letter_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.homeImp.company_request_letter =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleNoc(e) {
            let reader = new FileReader();
            this.homeImp.bank_noc_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.homeImp.bank_noc = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
    },

    computed: {
        homeImprovementOptions() {
            return HOME_IMPROVEMENT_TYPES.map((ele) => {
                return {
                    item: ele,
                    name: ele,
                };
            });
        },
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
};
</script>
<style scoped>
.buyers {
    border: 1px solid red;
}
</style>
